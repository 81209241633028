import logo from './logo.svg';
import './App.css';
import UserForm from './UserForm.js'
import ProductManager from './ProductManager.js'
import Login from './Login.js'
import UserInstruction from './userInstructin.js';
import Cookies from 'js-cookie';
import { Routes, Route, Navigate,useHistory } from 'react-router-dom'
import { useEffect } from 'react';
//import { useEffect } from 'react';

function App() {
  return (
    <Routes>
      <Route path="/UserForm" element={<UserForm />} />
      <Route path='/UserInstruction' element={<UserInstruction />}></Route>
      <Route path="/ProductManager" element={<ProductManager />} />
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Navigate to="/UserForm" />} />
    </Routes>
  );
}

export default App;
