import React, { Component, useEffect, useState } from "react";
import axios from 'axios';
import Cookies from "js-cookie";
import config from "./constants.js"
import { useTranslation } from "react-i18next";
import {DownOutlined} from "@ant-design/icons"
import UserInstruction from "./userInstructin.js";
import {Button, Select, Image, Input, Spin, message, Modal, Menu, Dropdown, Form, Layout} from "antd"
import './userform.css'

function UserForm() {
    const { t } = useTranslation();
    let clickCount = 0
    const [lang] = useState(localStorage.getItem("lang").toUpperCase())
    const [defaultCurrency, setDefaultCurrency] = useState('')
    const [showInstruction, setShowInstruction] = useState(false)
    const [adsList, setADSList] = useState([])
    const [sysCOEConfig, setSYSCOEConfig] = useState([])
    const [showResult, setShowResult] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [latestUpdateDate, setLatestUpdateDate] = useState(null)
    const [secondDensity, setSecondDensity] = useState('')
    const [secondSpe,setSecondSpe] = useState('')
    const [secondDoor, setSecondDoor] = useState('')
    const [secondWeight, setSecondWeight] = useState('')
    const [rate, setRate] = useState(1)
    const [totalVisitors, setTotalVisitorsCount] = useState(0)
    const [totalTVisitors, setTotalTVisitorsCount] = useState(0)
    const [rateList, setRateList] = useState([])
    const [rawList, setRawList] = useState([])
    const [totalPerPrice, setTotalPerPrice] = useState([0])
    const [weftDen, setWeftDen] = useState(0)
    const [doorList, setDoorList] = useState([])//门幅
    const [yarn, setYarn] = useState([])//纱支
    const [data, setData] = useState([])
    const [userInfo, setUerInfo] = useState({})
    const [open, setOpen] = useState(false)
    const [password, setPassword] = useState(null)
    const [nickName, setNickName] = useState(null)
    const [datadsource, setDatadSource] = useState([
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', perprice: 0, difficuty: t("simple"), rate:0.12, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]}, 
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', perprice: 0, difficuty: t("difficult"), rate:0.14, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]},
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', perprice: 0, difficuty: t("complex"), rate:0.16, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]}, 
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', perprice: 0, difficuty: t("jSimple"), rate:0.19, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]},
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', perprice: 0, difficuty: t("jDiffcult"), rate:0.2, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]}, 
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', perprice: 0, difficuty: t("jComplex"), rate:0.22, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]},
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', perprice: 0, difficuty: t("jpComplex"), rate:0.05, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]},
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', perprice: 0, difficuty: t("jpxComplex"), rate:0.035, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]}])
    // const instruct = [{lang: 'zh', desc: [
    //     '1、以上价格均遵循出口欧美质量标准，全精梳织造，全工艺全流程环保染色，色牢度3级以上，三洗缩水率4%以内 三次滚筒烘干，并按照美国标准在三轮滚筒烘干机中烘干。',
    //     '2、如果不确定面料的难易程度请发照片给 微信 13656850833 帮你确认是哪个成品价格。'
    // ]}, {lang: "en", desc: [
    //     '1. The above prices are in compliance with export quality standards to Europe and the United States. They are fully combed and weaved, and the entire process is environmentally friendly dyeing. The color fastness is level 3 or above, the shrinkage rate in three washes is within 4%, and it is tumble dried three times in accordance with American standards. Dry in tumble dryer.',
    //     '2. If you are not sure about the difficulty of the fabric, please send a photo to WeChat 13362551701 Whatsapp: 13362551701 to help you confirm the price of the finished product.',
    //     '3. The FOB price is the price per yard to Shanghai port in China. Please select the currency of your desired country for FOB calculation.'
    // ]}]
    const DropdownMenu = (
        <Menu>
            <Menu.Item key="1-1" onClick={()=>{setOpen(true)}}>{t("change password")}</Menu.Item>
            <Menu.Item key="1-2"><a onClick={()=>{signout()}}>{t("sign out")}</a></Menu.Item>
        </Menu>
    )
    const handleVisit = ()=>{
        axios.post(config.APIS_URL.INCREASE_VISTORS).then(res=>{
            console.log("访问+1")
            localStorage.setItem("lastVisit", new Date())
        })
    }
    const pullDescContent = () => {
        axios.post(`${config.APIS_URL.GET_MGR_INSTRUCTION}1/${lang.toLowerCase()}`).then(res=>{
            if(res.status==200 && res.data.code==200){
                setShowInstruction(res.data.display)
            }
            else if(res.status!=200 || res.data.code!=200){
                message.error("network error")
            }
        }).catch(ex=>{
            message.error(ex)
        })
    }
    const fetchUserData = () => {
        var token = Cookies.get("fabric")
        if(token){
            axios.get(config.APIS_URL.GET_USER_INFO,
                {headers: {Authorization:`Bearer ${token}`}}).then(res=>{
                if(res.status==200 && res.data.code==200){
                    setUerInfo(res.data)
                }
                else{
                    message.error(t("auth msg"))
                    Cookies.remove("fabric", {domain: window.location.hostname.replace(/th|tp|www/,"")})
                }
            }).catch(ex=>{})
        }
    }
    useEffect(()=>{
        fetchUserData()
        //i18n.changeLanguage("zh")
        const temFun = async ()=>{
            let lastVisit = localStorage.getItem("lastVisit")
            if(lastVisit){
                lastVisit = new Date(lastVisit)
                lastVisit.setHours(lastVisit.getHours() + 1)
            }
            if(!lastVisit || lastVisit<new Date()){
                handleVisit()
            }
            axios.get(config.APIS_URL.USER_GET_PRODUCT).then(res=>{
                if(res.data.code==200){
                    console.log(res.data)
                    var raw = []
                    res.data.product_list.map(item=>{
                        if(raw.findIndex(f=>f.value==item.raw_name)<0){
                            raw.push({value: item.raw_name, lable: item.raw_name})
                        }
                    })

                    setRawList(raw)
                    setDoorList(res.data.door_list) 
                    setData(res.data.product_list)
                    setRateList(res.data.rate_list)
                    setLatestUpdateDate(res.data.latestDate)
                    //rate = res.data.rate_list[0].rate
                }
                else{
                    message.error("network error")
                }
                console.log(res)
            }).catch(ex=>{
                message.error("network error")
                console.log(ex)
            })
        }
        document.title = t("mainTitle")
        temFun()
        pullVistors()
        pullDescContent()
        pullCOEConfig()
    },[])
    const handleClickADS = link => {
        if(!link){
            return
        }
        window.open(link)
    }
    const pullADSData = () => {
        axios.post(config.APIS_URL.GET_MGR_ADS).then(res=>{
            if(res.status==200 && res.data.code==200){
                setADSList(res.data.list)
            }
            else{
                message.error("network error")
            }
        }).catch(ex=>{
            message.error(message)
        })
    }

    const pullCOEConfig = () => {
        axios.get(config.APIS_URL.GET_SYS_COE_CONFIG).then(res=>{
            if(res.status==200 && res.data.code==200){
                setSYSCOEConfig(res.data.data)
            }
            else{
                message.error("network error")
            }
        }).catch(ex=>{
            message.error(message)
        })
    }
    
    const pullVistors = () => {
        var total = 0
        var totalCount = 0
        axios.get(config.APIS_URL.GET_VISTORS_URL).then(res=>{
            if(res.status==200 && res.data.code==200){
                console.log(res)
                total = total + Math.round(res.data.count)
                totalCount = totalCount +  Math.round(res.data.total)
                var pre_cnt = res.data.pre_count
                var timerange = res.data.time_range

                var percentage = 0;
                timerange.map(item=>{
                    var timestart = new Date()
                    timestart = timestart.setHours(item.start.split(":")[0], item.start.split(":")[1], item.start.split(":")[2])

                    var current = new Date()
                    if(current.getTime()>=timestart){
                        percentage += item.pct
                    }
                })
                total += Math.round(pre_cnt*percentage)
                totalCount += Math.round(pre_cnt*percentage)
                setTotalVisitorsCount(total)
                setTotalTVisitorsCount(totalCount)
            }
            else if(res.status!=200 || res.data.code!=200){
                message.error("network error")
            }
        }).catch(ex=>{
            message.error(ex)
        })
    }
    const DoorChange = (value, index) => {
        var temp = [... datadsource]
        temp.map((item,index)=>{
            item.door = value
        })
        setDatadSource(temp)
    }
    // const LatitudeChange = (item, index) => {
    //     datadsource[index].latitude = item
    // }
    const YarnChange = (item, index) => {
        let tempdata = [... datadsource]
        tempdata[index].yarn = item
        setDatadSource(tempdata)
    }
    const RawChange = (item, index) => {
        var longl = index<=2?0:1
        var tempdata = data.filter(f=>f.raw_name==item && f.longLati==longl)
        var source = [... datadsource]
        source[index].yarn = ""
        source[index].raw_name = item
        source[index].yarnList = tempdata
        setDatadSource(source)
    }
    
    const CircleChange = (e, index) => {
        let tempdata = datadsource
        tempdata[index].circle = e.target.value
        setDatadSource(tempdata)
    }
    const CircleDataChange = (e, index) => {
        let tempdata = datadsource
        tempdata[index].circledata = e.target.value
        setDatadSource(tempdata)
    }
    const rateChange = (value) => {
        var tmp = rateList.find(f=>f.value==value)
        var contryrate = tmp.rate
        var contryCurrency = tmp.value
        setDefaultCurrency(contryCurrency)
        var source = [... datadsource]
        source.map((item, index)=>{
            var base_price = parseFloat(totalPerPrice + ((weftDen/2.54)*sysCOEConfig[index].k))
            source[index].price = (base_price*1.03+2.31+sysCOEConfig[index].p).toFixed(2)

            if(lang.toLowerCase()=="en"){ //英文模式
                var  deviator = ((secondWeight - 120)/15)*0.2 //价格偏移量
                source[index].price = (parseFloat(source[index].price) + deviator).toFixed(2)
            }
            
            source[index].raw_price = base_price.toFixed(2)
            source[index].fob_price = (source[index].price*0.9144/(contryrate*1.08)).toFixed(2)
        })
        setRate(contryrate)
        setDatadSource(source)
    }
    /*const ChangeLang = () => {
        let nextLang = ""
        if(lang.toUpperCase()=="EN"){
            nextLang = "ZH"
        }
        else{
            nextLang = "EN"
        }
        let tempdata = [... datadsource]
        tempdata.map(item=>{
            if(nextLang.toLowerCase()=='en'){
                
                item.price = (parseFloat(item.price)+(((secondWeight-120)/15)*0.2)).toFixed(2)
                item.fob_price = (parseFloat(item.price)*0.9144/(rate*1.08)).toFixed(2)
            }
            else {
                item.price = (parseFloat(item.price)- (((secondWeight-120)/15)*0.2)).toFixed(2)
                item.fob_price = (parseFloat(item.price)*0.9144/(rate*1.08)).toFixed(2)
            }
        })
        
        setLang(nextLang)
        //setShowDescTextList([])
        i18n.changeLanguage(nextLang.toLowerCase())
        //localStorage.setItem("lang", nextLang.toLowerCase()) //保存默认语言选择
        tempdata[0].difficuty = t("simple")
        tempdata[1].difficuty = t("difficult")
        tempdata[2].difficuty = t("complex")
        tempdata[3].difficuty = t("jSimple")
        tempdata[4].difficuty = t("jDiffcult")
        tempdata[5].difficuty = t("jComplex")
        setDatadSource(tempdata)
    }*/
    const GetResult = () => {
        // let auth = userInfo&&userInfo.code==200
        // if(!auth){
        //     message.error(t("loginTips"))
        //     return
        // }
        var secondDensity = '',
        secondSpe = '',
        secondDoor ='',
        secondWeight0 = '',
        totalweight=0,
        totalDen1 = 0,
        totalDen2 = 0,
        totalPerPrice0=0,
        weftDen0 = 0 //纬线上的密度和
        var length = datadsource.find(f=>f.door!=''&&(f.yarn!='')&&f.circle!=''&&f.circledata!='')
        if(length==undefined || length<=0){
            message.warning(t("empty tips"))
            return
        }
        var continueState = true
        datadsource.map((item, index)=>{
            if(item.circle!=''&& item.circle != undefined &&
                item.circledata!=''&& item.circledata != undefined &&
                item.door!=''&& item.door != undefined &&
                item.yarn!=''&& item.yarn!=undefined){
                try{
                    var orindex = item.yarnList.findIndex(f=>f.value==item.yarn) //原料
                    var doorIndex = doorList.findIndex(f=>f.value==item.door)
                    if((orindex<0 || doorIndex<0) && continueState){
                        message.info(t("calcTips"))
                        continueState = false
                    }
                    if(continueState){
                        var doormap = doorList[doorIndex].map

                        var temp = item.circledata/item.circle*2.54
                        item.density = temp.toFixed(2) //密度
                        if(index<=2){ //经线上 每米克重
                            if(secondSpe!=''){
                                secondSpe += '+' + item.yarn
                            }
                            else {
                                secondSpe += item.yarn
                            }
                            //secondSpe += item.yarn + "+"
                            totalDen1+=temp
                            item.perweight = (583.1/item.yarnList[orindex].spe*temp*item.door/1000).toFixed(2)
                        }
                        else { //纬线上
                            if(secondSpe!='')
                                secondSpe += '*'+item.yarn
                            else
                                secondSpe += item.yarn
                            //secondSpe += item.yarn + "*"
                            totalDen2 += temp
                            item.perweight = (583.1/item.yarnList[orindex].spe*temp*doormap/1000).toFixed(2)
                            
                            weftDen0 = temp + weftDen0
                        }
                        totalweight += parseFloat(item.perweight)
                        
                        var originalprice = undefined //原料价格
                        originalprice = item.yarnList[orindex].oriprice
                        if(index==0){ //每米价格
                            item.perprice = (parseFloat(item.perweight) *1.08*originalprice/100).toFixed(2)
                        }
                        else {
                            item.perprice = (parseFloat(item.perweight)*1.1*originalprice/100).toFixed(2)
                        }  
                    }    
                }
                catch(ex){}          
            }
            if(continueState)
             totalPerPrice0 = parseFloat(item.perprice) + totalPerPrice0
        })
        if(continueState){
            var tempdata = [... datadsource]
            secondWeight0 = Math.round(totalweight/parseFloat(datadsource[0].door)/2.54*100) //克重 GMS
            tempdata.map((item, index)=>{
                var base_price = parseFloat(totalPerPrice0 + ((weftDen0/2.54)*sysCOEConfig[index].k))
                tempdata[index].price = (base_price*1.03+2.31+sysCOEConfig[index].p).toFixed(2)
                
                if(lang.toLowerCase() == 'en') {//英文模式
                    var temp_price = tempdata[index].price
                    
                    var  deviator = ((secondWeight0 - 120)/15)*0.2 //价格偏移量
                    tempdata[index].price = (parseFloat(temp_price) + (deviator<0?0:deviator)).toFixed(2)
                }
                
                tempdata[index].raw_price = base_price.toFixed(2)
                tempdata[index].fob_price = (tempdata[index].price*0.9144/(rate*1.08)).toFixed(2)
            })
            setDatadSource(tempdata)
            var totalden = 0
            if(totalDen1!=0 && totalDen2!=0){
                totalden = Math.round(totalDen1).toString() + '*' + Math.round(totalDen2).toString()
            }
            else if(totalDen1!=0) {
                totalden = Math.round(totalDen1).toString()
            }
            else{
                totalden = Math.round(totalDen2).toString()
            }
            
            /*if(secondSpe.charAt(secondSpe.length-1)=='*' || secondSpe.charAt(secondSpe.length-1)=='+'){
                secondSpe = secondSpe.substring(0, secondSpe.length-1)
            }*/
            
            //setDatadSource(datadsource)
            setSecondSpe(secondSpe)
            setSecondDensity(totalden)
            setSecondDoor(datadsource[0].door)
            setSecondWeight(Math.round(secondWeight0*0.93))
            setShowResult(true)
            setTotalPerPrice(totalPerPrice0)
            setWeftDen(weftDen0)
        }
    }

    const handleUpdatePasswd = () => {
        if((password == null || password.toString().trim()=="") && (nickName == null || nickName.toString().trim()=="") ){
            message.warning(t("changeTip"))
            return
        }
        setShowLoading(true)
        var parm = {}
        if(password){
            parm.password = password
        }
        if(nickName){
            parm.name = nickName
        }
        axios.post(config.APIS_URL.UPDATE_USER_INFO, parm, 
            {headers: {Authorization: `Bearer ${Cookies.get("fabric")}`}}).then(res=>{
            if(res.data.code==200){
                fetchUserData()
                message.success(t("successTip"))
            }
            else{
                message.success(t("failTip"))
            }
            setShowLoading(false)
            setOpen(false)
        }).catch(ex=>{
            message.error(ex.response.data.detail)
            setShowLoading(false)
            setOpen(false)
            console.log(ex)
            if(ex.response.status==403){
                message.error(t("auth msg"))
                Cookies.remove("fabric", {domain: window.location.hostname.replace(/th|tp|www/,"")})
            }
        })
    }

    const signout = () =>{
        Modal.confirm({
            "content": t("exitTip"),
            onOk: ()=>{
                Cookies.remove("fabric", {domain: window.location.hostname.replace(/th|tp|www/,"")})
                window.location.reload()
                setUerInfo({})
            }
        })
    }

    return (
        <Layout style={{minHeight:'100vh', background:'#fff'}}>
            <Layout.Content>
            <div className="containter-box">
            <div style={{marginLeft: '10px',marginTop: '5px' , fontSize: '16px', color: 'rgba(0,0,0,0.5)', display:'flex', justifyContent: 'space-between'}}>
                <div>
                    <div>
                        {t("visitors")}
                        <span style={{marginLeft: '10px'}}>{totalVisitors}</span>
                    </div>
                    <div>
                        {t("total visitors")}
                        <span style={{marginLeft: '10px'}}>{totalTVisitors}</span>
                    </div>
                </div>
                {/* <a onClick={()=>ChangeLang()} type="link" style={{float: "right", color: '#1677ff', cursor: 'pointer', float: 'right'}}>{lang}</a> */}
            </div>
          <Spin spinning={showLoading} tip="loading..." size="lager">
            <div style={{display:'flex'}}>
                <div className="header">{t("mainTitle")}</div>
                {
                    userInfo&&userInfo.code==200?
                    <Dropdown className="login-btn" trigger={["click"]} overlay={DropdownMenu} style={{minWidth: '80px'}}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        Hi, {userInfo.name} <DownOutlined />
                        </a>
                    </Dropdown>
                    :
                    <a href={`${config.LoginURL}?resource=${encodeURIComponent(window.location.href)}`} className="login-btn" style={{minWidth: '40px'}}>{t("sign in")}</a>
                }
            </div>
            {/* <div className="searchBoxContainer">
                <div className="searchBox">
                    {fileToUpload?fileToUpload.name:t("search tips")}
                </div>
                <Upload className="hello" accept="image/png, image/jpeg, image/jpg" showUploadList={false} maxCount={1} beforeUpload={(file)=>handleBeforeUpload(file)} onRemove={()=>{setFileToUpload(null)}}>
                    <Button style={{height: '38px'}} icon={<SearchOutlined />}>{t?t("search Img"):null}</Button>
                </Upload>
            </div> */}
            <div style={{display:"flex", justifyContent: 'space-between', marginLeft: '10px'}}>
                <div>{/*<QuestionOutlined style={{color:"#1677ff", marginRight: '5px'}} />*/}
                <a href={`#/UserInstruction?lang=${lang}`}>{t("instruction")}</a></div>
            {
                latestUpdateDate?(
                    <div className="latest-update">
                        <span>{t("latet update")}</span>
                        <span style={{marginLeft:'10px', color: 'red', marginRight: '5px'}}>{latestUpdateDate}</span>
                    </div>
                ):null
            }
            </div>
             <div className="overTable">
                <table>
                    <tbody>
                        <tr>
                            <th className="title">{t("door")}</th>
                            <th className="title">{t("longLati")}</th>
                            <th className="title">{t("rawComp")}</th>
                            <th className="title">{t("yarn")}</th>
                            <th className="title">{t("loop")}</th>
                            <th className="title">{t("loopData")}</th>
                        </tr>
                        {
                            datadsource.slice(0, 6).map((item, index)=>(
                                <tr key={index}>
                                    <td><Select options={doorList} onChange={(value)=>{DoorChange(value, index)}} value={item.door}></Select></td>
                                    {/* <td><Select options={latitude} defaultValue={index>2?'纬线':'经线'} onChange={(value)=>LatitudeChange(value, index)}></Select></td> */}
                                    <td className="title" style={index>2?{color:'red'}:null}>{index>2?t("Latitude"):t("Longitude")}</td>
                                    <td><Select options={rawList} onChange={(value)=>RawChange(value, index)} value={item.raw_name}></Select></td>
                                    <td><Select options={item.yarnList} onChange={(value)=>YarnChange(value, index)} value={item.yarn}></Select></td>
                                    <td><Input key={item.circle} defaultValue={item.circle} type="number" placeholder={t("inputHolder")} onChange={(e)=>CircleChange(e, index)}>{/*item.circle*/}</Input></td>
                                    <td><Input key={item.circledata} defaultValue={item.circledata} type="number" placeholder={t("inputHolder")} onChange={(e)=>CircleDataChange(e, index)}>{/*item.circledata*/}</Input></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
             </div>
             
             <div style={{display:'flex', flexDirection: 'row', margin: '30px', justifyContent:'center'}}>
                <Button type="primary" className="calcButton" size="40" onClick={()=>GetResult()}>{t("calcRes")}</Button>
                <Select key="currency" style={{width: '80px'}} options={rateList} value={rate==1?t("currency"):defaultCurrency} 
                    onChange={(value)=>rateChange(value)}></Select>
            </div>

             {
                showResult?(
                <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                    <div className="overTable">
                        <table className="secondTable">
                            <tbody>
                                <tr><th>{t("specification")}</th><th>{t("density")}</th><th>{t("door")}</th><th>{t("secondWeight")}</th></tr>
                                <tr>
                                    <td>{secondSpe}</td>
                                    <td>{secondDensity}</td>
                                    <td>{secondDoor}</td>
                                    <td>{secondWeight}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div className="overTable">
                        <table style={{marginTop: '30px'}}>
                            <tbody>
                                <tr>
                                    <th className="result-title">{t("longLati")}</th>
                                    <th className="result-title">{t("yarn")}</th>
                                    <th className="result-title">{t("density")}</th>
                                    <th className="result-title">{t("perWeight")}</th>
                                    {userInfo&&userInfo.type>=100?<th className="result-title">{t("perPrice")}</th>:null}
                                    <th className="result-title">{t("difficuty")}</th>
                                    <th className="result-title">{t("price")}</th>
                                    {userInfo&&userInfo.type>=90?<th className="result-title">{t("greyPrice")}</th>:null}
                                    <th className="result-title">{t("fobPrice")}</th>
                                </tr>
                                {
                                    datadsource?datadsource.map((item, index)=>(
                                        <tr>
                                            <td className="result-title" style={index>2?{color:'red'}:null}>{index<=2?t("Longitude"):t("Latitude")}</td>
                                            <td>{item.yarn}</td>
                                            <td>{item.density}</td>
                                            <td>{item.perweight}</td>
                                            {userInfo&&userInfo.type>=100?<td>{item.perprice}</td>:null}
                                            <td>{item.difficuty}</td>
                                            <td>{item.price}</td>
                                            {userInfo&&userInfo.type>=90?<td>{item.raw_price}</td>:null}
                                            <td>{item.fob_price}</td>
                                        </tr>
                                    )) : null
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='desc-box'>
                        {
                            lang.toLowerCase()=='en'?(<div className="desc">instruct: </div>):(<div className="desc">说明: </div>)
                        }
                        {
                            instruct.find(f=>f.lang==lang.toLowerCase()).desc.map((item, index)=>(
                                <div key={`d-${index}`} className="desc">{item}</div>
                            ))
                        }
                    </div> */}
                </div>):null
             }
        <div>
        {
            showInstruction?<UserInstruction lanaguage={lang} />:null
        }
        {/*adsList&&adsList.length>0?<div className="ads-title">{t("partner")}</div>:null*/}
        {/* <div className="ads-container">
            {
                adsList.map((item,index)=>(
                    <div key={`a-${index}`} onClick={()=>handleClickADS(item.link)} className='ads-container-box'>
                        <Image preview={false} style={{height: '60px'}} src={`https://images.weserv.nl/?url=${config.HOST}/${item.img_url}`}></Image>
                        <div title={lang.toLowerCase()=='zh'?item.txt:item.txtWithEN} className="ads-sub-title">
                            {lang.toLowerCase()=='zh'?item.txt:item.txtWithEN}
                        </div>
                    </div>
                ))
            }
        </div>  */}
        </div>
        <Modal title={t("change password")}
              centered
              onCancel={()=>{setOpen(false)}}
              onOk={()=>{handleUpdatePasswd()}}
              open={open}>
            <Form>
                <Form.Item label={t("account")}><Input disabled value={userInfo.account} /></Form.Item>
                <Form.Item label={t("nickName")}><Input onChange={(e)=>{setNickName(e.target.value)}} /></Form.Item>
                <Form.Item label={t("password")}><Input type="password" onChange={(e)=>{setPassword(e.target.value)}}></Input></Form.Item>
            </Form>
        </Modal>
        </Spin>
    </div>
            </Layout.Content>
            {
                lang=="ZH"?<Layout.Footer className="footer">Copyright© 2024 提花面料价格计算 <a href="http://beian.miit.gov.cn/">浙ICP备2024083871号</a></Layout.Footer>:null
            }
            
        </Layout>
    )
}

export default UserForm