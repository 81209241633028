import React, {useState, useEffect} from "react";
import ShowLinkDescription from "./components/showLinkDescription.js";
import { useSearchParams } from "react-router-dom";
import config from "./constants.js"
import axios from "axios"
import i18n from './i18n'
import { useTranslation } from "react-i18next";
import {message} from "antd"

function UserInstruction({lanaguage}) {
    const [showDescTextList, setShowDescTextList] = useState([])
    const {t} = useTranslation()

    const pullDescContent = (lanaguage) => {
        axios.post(`${config.APIS_URL.GET_MGR_INSTRUCTION}1/${lanaguage.toLowerCase()}`).then(res=>{
            if(res.status==200 && res.data.code==200 && res.data.list.length>0){
                console.log(res.data.list[0].description)
                let arr = res.data.list[0].description.split("\n")
                setShowDescTextList(arr)
            }
            else if(res.status!=200 || res.data.code!=200){
                message.error("network error")
            }
        }).catch(ex=>{
            message.error(ex)
        })
    }
    useEffect(()=>{
        if(lanaguage){
            pullDescContent(lanaguage)
        }
        else{
            var arr = window.location.href.split("?")
            let lang = arr[1].split("=")[1]
            pullDescContent(lang)
        }
    },[])
    
    return(
        <div className="containter-box">
            <div className="header">{t("instruction")}</div>
            <div className="desc-box">
        {
            showDescTextList.map((item, index)=>(
                index==0?
                <div className="desc" style={{fontWeight: 600}}>{item}</div>:
                ((item.indexOf("<")>=0 && item.indexOf(">")>=0 && (!/\s\s/.test(item)))?
                <ShowLinkDescription key={`desc${index}`} item={item} className={"desc"} />:
                    (
                        /\s\s/.test(item) ?
                            (item.indexOf("<")>=0 && item.indexOf(">")>=0?<ShowLinkDescription key={`subdesc${index}`} item={item} className={"sub-desc"} />: <div className="sub-desc">{item}</div>):
                        <div className="desc">{item}</div>
                    )
                )
            ))
        }
        </div>
        </div>
    )
}

export default UserInstruction
