import React from 'react';
import axios from 'axios'
import config from "./constants"
import HeaderComponent from './components/HeaderComponent'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Input, Modal, message, Spin } from 'antd';
import RateManager from './Manager/rateManager.js'
import DoorManager from './Manager/doorManager.js'
import Visitors from "./Manager/visitors.js"
import ProductMgr from "./Manager/productMgr.js"
import Instructions from "./Manager/instructions.js"
import Ads from "./Manager/ads.js"
import Users from "./Manager/users.jsx"
import LoginInstruction from "./Manager/loginDescription.jsx"
import CargoManager from './Mall/cargoMagarge.jsx';
import OrderManager from './Mall/orderManager.jsx';
import StyleManager from './Mall/stylesManager.jsx';
import ChildManager from './Mall/childManager.jsx';
import Description from './Mall/description.jsx';
import PreVisitorsManager from './Mall/mallPreVisitManager.jsx';
import COEManager from './Manager/coe.jsx';
import FakeManager from './Mall/fakeManger.jsx';
import "./Manager/manager.css"
 
class ProductManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentKey: 'products',
      login:false,
      loading: false,
      userName: undefined,
      pwd: undefined,
    };
  }

  handlePwdChange = e =>{
    this.setState({pwd:e.target.value})
  }

  handleNameChange = e =>{
    this.setState({userName: e.target.value})
  }

  handleLogin = () => {
      if(this.state.userName==undefined || this.state.userName.trim()=='' || this.state.pwd==undefined || this.state.pwd.trim() == ''){
          message.warning("请输出用户名和密码")
          return
      }
      this.setState({loading: true})
      axios.post(config.APIS_URL.LOGIN_MGR_URL, {'name': this.state.userName, 'password': this.state.pwd}).then(res=>{
          if(res.data.code==200){
              this.setState({login: true})
          }
          else{
              message.warning("账号或密码错误")
          }
          this.setState({loading: false})
      }).catch(ex=>{
          console.error(ex)
          this.setState({loading: false})
      })
  }
  
  onMenuClick = args => {
    var title = ''
    if(args=='products'){
        title = '产品信息维护'
    }
    else {
        title = '统计'
    }
    this.setState({currentKey: args})
    console.log(args)
  }
 
  render() {
    const { currentKey, login, loading } = this.state;
      
    return (
        <div>
          {
            login==true?(
              <div className='containter-box-1'>
                <HeaderComponent onMenuClick={this.onMenuClick}></HeaderComponent>
                <div style={{padding: '20px'}}>
                {
                    (currentKey=='rates'?<RateManager />: 
                    (currentKey=='doors'?<DoorManager />:
                    (currentKey=='products'?<ProductMgr />:
                    currentKey=='stastics'?<Visitors />:
                    (currentKey=='desc'?<Instructions />:
                    (currentKey=="ads"?<Ads></Ads>:
                    (currentKey=="users"?<Users />:
                    (currentKey=="logindesc"?<LoginInstruction />:
                    (currentKey=="cargo_mgr"?<CargoManager />:
                    (currentKey=="order_mgr"?<OrderManager />:
                    (currentKey=="style_mgr"?<StyleManager />:
                    (currentKey=="child_mgr"?<ChildManager />:
                    (currentKey=="text_mgr"?<Description />:
                    (currentKey=="pre_mgr"?<PreVisitorsManager />:
                    (currentKey=="fake_mgr"?<FakeManager />:
                    (currentKey=="syscoe"?<COEManager /> : null))))))))))
                    ))
                  )))
                }
                </div>
              </div>
            ):<Modal className="login-box" title="登录" mask okText="登录" cancelText="取消" open={!login} cancelButtonProps={{style: {display: 'none'}}}
              onOk={()=>this.handleLogin()}
            >
                
                {loading?<Spin size='large' tip="loading">
                  <div><Input placeholder='请输入用户名' onChange={this.handleNameChange} prefix={<UserOutlined />}></Input></div>
                  <div style={{marginTop: '30px'}}><Input type='password' onChange={this.handlePwdChange} onKeyDown={(e)=>{if(e.key=="Enter"){this.handleLogin()}}} placeholder='请输入密码' prefix={<LockOutlined />}></Input></div>
                </Spin>:(
                  <div>
                    <div><Input placeholder='请输入用户名' onChange={this.handleNameChange} prefix={<UserOutlined />}></Input></div>
                    <div style={{marginTop: '30px'}}><Input type='password' onChange={this.handlePwdChange} onKeyDown={(e)=>{if(e.key=="Enter"){this.handleLogin()}}} placeholder='请输入密码' prefix={<LockOutlined />}></Input></div>
                  </div>
                )}
            </Modal>
          }
        </div>
    );
  }
}
 
export default ProductManager;