import React, {useState} from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import {CalculatorOutlined,ProductOutlined} from "@ant-design/icons"

const { Header, Content, Footer } = Layout;

const items = [
  { 
    key: "fabric_caculate",
    label: "计算器管理", 
    icon: <CalculatorOutlined />,
    children:[
      {key: 'products', label: '原料管理'}, 
      {key: 'doors', label: '门幅管理'}, 
      {key: 'rates', label: '汇率管理'}, 
      {key: 'syscoe', label: '系统系数修改'}, 
      {key: 'desc', label: '说明管理'}, 
      {key: 'logindesc', label: '登录说明'}, 
      {key: 'ads', label: '加盟管理'}, 
      {key: 'users', label: '用户管理'}, 
      {key: 'stastics', label: '统计'}]
  },
  {
    key: "fabirc_mall",
    label: "商城管理",
    icon:<ProductOutlined />,
    children: [
      {key: "cargo_mgr", label: '商品管理'},
      {key: 'order_mgr', label: "订单管理"},
      {key: 'style_mgr', label: "花型管理"},
      {key: 'child_mgr', label: "代理用户管理"},
      {key: 'text_mgr', label: "文字说明"},
      {key: 'fake_mgr', label: "上传虚拟售货量"},
      {key: 'pre_mgr', label: "统计"}
    ]
  }
];

const HeaderComponents = ({onMenuClick}) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [contentIndex, setContentIndex] = useState(null);  

  return (
    <Layout>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100vw',
          height: '52px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Menu
          theme="dark"
          mode="horizontal"
          onClick={({ key }) => {onMenuClick(key); setContentIndex(key)}}
          defaultSelectedKeys='products'
          items={items}
          style={{ flex: 1, minWidth: 0, height: '52px', lineHeight:'52px' }}
        />
      </Header>
    </Layout>
  );
};

export default HeaderComponents;